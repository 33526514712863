@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap");

html {
  --background: #353535;
  --button-bg-color: #c2f261;
  --button-active-bg-color: #a3f500;
  --button-border-color: #78ac0e;
  --button-side-color: #88c310;
  --button-text-color: #353535;
  --shadow: rgba(0, 0, 0, 0.3);
}

body {
  background: var(--background);
}

button {
  text-align: center;
  vertical-align: middle;
  border: solid 1px var(--button-border-color);
  border-radius: 10px;
  font-family: "Anonymous Pro", monospace;
  margin: 0 auto 5px auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 30px 0;
  background: var(--button-bg-color);
  text-shadow: 0 1px 0px #fff;
  box-shadow: 0 8px 0 var(--button-side-color), 0 5px 3px var(--shadow);
  color: #353535;
  font-size: 16px;
  cursor: pointer;
  transition: all 50ms, background-color 750ms;
  user-select: none;
}

.started:not(.paused) .pause-button,
.started.paused.panel-open .pause-button,
.panel-open .reset-button {
  background: var(--button-active-bg-color);
  box-shadow: 0 1px 0 var(--button-side-color), 0 3px 3px var(--shadow);
  transform: translateY(7px);
}

.started.paused .pause-button {
  box-shadow: 0 8px 0 var(--button-side-color), 0 5px 3px var(--shadow);
  transform: translateY(0);
}

/* button:hover {
  box-shadow: 0 2px 0 var(--button-side-color), 0 4px 3px var(--shadow);
  transform: translateY(1px);
} */

.controls ul {
  display: grid;
  list-style: none;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 0;
  padding: 10px;
  padding-bottom: 20px;
}

.controls li button {
  width: 100%;
  height: 100%;
}
