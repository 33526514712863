.average {
  align-items: top;
  justify-content: center;
  display: flex;
}

table caption {
  font-size: 24px;
  text-align: left;
  padding-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table tr {
  user-select: all;
}

table th {
  text-align: left;
  padding: 0;
}

table td {
  text-align: right;
}
