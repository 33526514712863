@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%;
  background-color: #353535;
  overflow: hidden;
  position: relative;
  margin: 0;
}

#root {
  height: 100%;
  display: grid;
}

.App {
  background-color: #353535;
  color: #c2f261;
  font-family: "Anonymous Pro", monospace;
  font-size: 36px;
}

.main-area {
  display: grid;
  height: 100%;
  width: 100%;
  text-align: center;
  grid-template-rows: 2fr 1fr auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

::selection {
  background: #c2f261;
  color: #353535;
}

.panel {
  --panel-backdrop: rgba(0, 0, 0, 0.85);
  --dialog-bg: #353535;
  --dialog-border: #c2f261;
  --dialog-color: #c2f261;
}

.panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
}

.panel-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--panel-backdrop);
  display: flex;
  font-size: 20px;
  z-index: 2;
  cursor: pointer;
}

.panel-content {
  border: solid 4px var(--dialog-border);
  padding: 20px;
  background: var(--dialog-bg);
  color: var(--dialog-color);
  justify-content: center;
  align-self: center;
  margin: auto;
  width: 80%;
  cursor: auto;
  z-index: 3;
  position: relative;
  font-size: 20px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.counter {
  font-size: 20vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.started.paused .counter {
  animation: 2s infinite steps(1, start) blink;
}

.paused.panel-open .counter {
  animation: none;
}

.editable {
  color: red;
}

.timer {
  display: flex;
  padding: 20px 0;
  justify-content: center;
}

.started.paused .timer {
  animation: 2s infinite steps(1, start) blink;
}

.paused.panel-open .timer {
  animation: none;
}

.editable {
  color: red;
}

.average {
  align-items: top;
  justify-content: center;
  display: flex;
}

table caption {
  font-size: 24px;
  text-align: left;
  padding-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table tr {
  -webkit-user-select: all;
      -ms-user-select: all;
          user-select: all;
}

table th {
  text-align: left;
  padding: 0;
}

table td {
  text-align: right;
}

html {
  --background: #353535;
  --button-bg-color: #c2f261;
  --button-active-bg-color: #a3f500;
  --button-border-color: #78ac0e;
  --button-side-color: #88c310;
  --button-text-color: #353535;
  --shadow: rgba(0, 0, 0, 0.3);
}

body {
  background: #353535;
  background: var(--background);
}

button {
  text-align: center;
  vertical-align: middle;
  border: solid 1px #78ac0e;
  border: solid 1px var(--button-border-color);
  border-radius: 10px;
  font-family: "Anonymous Pro", monospace;
  margin: 0 auto 5px auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 30px 0;
  background: #c2f261;
  background: var(--button-bg-color);
  text-shadow: 0 1px 0px #fff;
  box-shadow: 0 8px 0 #88c310, 0 5px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 0 var(--button-side-color), 0 5px 3px var(--shadow);
  color: #353535;
  font-size: 16px;
  cursor: pointer;
  transition: all 50ms, background-color 750ms;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.started:not(.paused) .pause-button,
.started.paused.panel-open .pause-button,
.panel-open .reset-button {
  background: #a3f500;
  background: var(--button-active-bg-color);
  box-shadow: 0 1px 0 #88c310, 0 3px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 0 var(--button-side-color), 0 3px 3px var(--shadow);
  transform: translateY(7px);
}

.started.paused .pause-button {
  box-shadow: 0 8px 0 #88c310, 0 5px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 0 var(--button-side-color), 0 5px 3px var(--shadow);
  transform: translateY(0);
}

/* button:hover {
  box-shadow: 0 2px 0 var(--button-side-color), 0 4px 3px var(--shadow);
  transform: translateY(1px);
} */

.controls ul {
  display: grid;
  list-style: none;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 0;
  padding: 10px;
  padding-bottom: 20px;
}

.controls li button {
  width: 100%;
  height: 100%;
}

